* {
  all: unset; /* Use initial spec values. */
  display: revert; /* Revert to browser's default. */
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  --monospace: ui-monospace, monospace;
  --sans-serif: system-ui;
  --background: #fff;
  --foreground: #37352f;

  background-color: var(--background);
  color: var(--foreground);
  font: normal 12px/1 var(--sans-serif);
  font-size: max(12px, 3vmax);
}

html[data-color-scheme="dark"] {
  --background: #2f3437;
  --foreground: #ebebeb;
}

@media (prefers-color-scheme: dark) {
  html:not([data-color-scheme="light"]) {
    --background: #2f3437;
    --foreground: #ebebeb;
  }
}

html,
body,
#root {
  height: 100%;
}

#root {
  align-items: center;
  display: flex;
  justify-content: center;
}

*:focus {
  outline: 0;
}
