.display {
  font-family: var(--monospace);
  font-size: 2.5em;
  font-weight: normal;
}

@media (width >= 500px) {
  .display {
    font-size: 3.5em;
  }
}
